var event_animation = (function(document, window) {

	var evt = [

		function(document, window) {      

      var htmls = document.querySelector('html');

			if(htmls.classList.contains('desktop')){ 
        
        // Parallax Effect
        var parallax = document.querySelectorAll('[data-toggle="parallax"]');
      
        parallax.forEach(function(element){
      
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: element,
              start: "top top",
              end: "bottom top",
              scrub: true
            }
          });
          
          gsap.utils.toArray(".parallax").forEach(layer => {
            const depth = layer.dataset.depth;
            const movement = -(layer.offsetHeight * depth)
            tl.to(layer, {y: movement, ease: "none"}, 0)
          });
        });

        // Fade Up Animation

        var slideUp = {
          distance: '70px',
          origin: 'bottom',
          delay: 375,
          interval: 200,
          duration: 600,
          opacity: 0,
          easing: 'ease-out'
        };

        var nodeList = document.querySelectorAll('.fadeUp');

        ScrollReveal().reveal(nodeList, slideUp);
      
      } 
      
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

event_animation.init();
